<template>
  <div class="content-view">
    <div class="content-view__wrapper">
      <Section limiter>
        <BreadCrumbs class="p-t-sm-20 p-t-md-20"
                     :params="{navigation, color: 'rgba(0,0,0,.58)'}"/>
        <h1 class="content-view__title">{{$event.title}}</h1>
        <div class="content-view__meta">
          <div class="content-view__meta-date">
            <Icon class="content-view__meta-icon"
                  viewport="0 0 20 20"
                  xlink="calender"/>
            {{$event.started_at
            ? $moment.unix($event.started_at).format(formatDate)
            : $moment.unix($event.created_at).format(formatDate)}}
          </div>
          <div class="content-view__meta-update">
            {{$event.place ? $event.place.address :$event.address}}
          </div>
          <div v-if="$event.cost"
               class="content-view__meta-update">{{ $t('events_view.cost') }}: {{$event.cost}}
          </div>
        </div>
        <div class="content-view__image-holder"
             v-if="$event.images">
          <img class="content-view__image"
               :src="$event.images[0]"/>
        </div>
        <p class="content-view__caption"
           v-if="$laptop">{{$event.annotation}}</p>
        <div class="content-view__main-text html-insert"
             v-html="$event.description"/>
      </Section>
      <Section v-if="$event.images && $event.images.length > 1"
               :limiter="$mobile">
        <Slider :height="$laptop ? 450 : 180"
                :indentation="$laptop ? 30 : 8"
                :items="$event.images.filter((item, index) => index > 0)"
                class="content-view__slider"
                component="ImageComponent"
                :count="1"
                :limiter="$laptop"/>
      </Section>
      <Section v-if="isShowMap"
               limiter>
        <h2 class="content-view__on-map-title">{{ $t('events_view.on_map') }}</h2>
        <OnMap :params="{sizeMap: {
                width: '100%', height: '400px'},
                coords: $event.place ? $event.place.location :$event.location}"
               :places="$route.params.type === 'news' ? [$event.place] : null"
               :events="$route.params.type === 'event' ? [$event] : null"
               class="content-view__on-map"/>
      </Section>
      <Section limiter>
        <VideoYouTube class="content-view__video-you-tube"
                      :params="{width: '730px', height: '400px', src: $event.youtube}"
                      v-if="$event.youtube"/>
      </Section>
      <Section>
        <Author :params="$event.author"
                class="content-view__author"
                v-if="$event.author"/>
      </Section>
      <Section limiter>
        <GuestReviews
            :params="{title: $t('comments.comments'), button: $t('comments.leave')}"
            typePage="places"/>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/events/${$route.params.id}`"
               :title="$event.title"
               :description="$event.annotation"/>
      </Section>
      <Section>
        <ThematicSlider :params="{array: $shuffle($news.data).slice(0, 3),
                                  component: 'EventCard',
                                  count: $mobile ? 1 : 3}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('events_view.news') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
                 class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                           :to="`${$locale.base}/news`">{{$laptop ? $t('home.watch_all.p_1') : ''}}
                {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                    viewport="0 0 20 20"
                    :rotate="90"
                    xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventView',
  data() {
    return {
      formatDate: 'DD MMMM YYYY HH:mm',
    };
  },
  created() {
    this.$store.dispatch('GET_EVENT_FROM_SERVER', this.$route.params.id);
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        count: 100500,
        city_id: this.$city.id,
      },
    });
  },
  computed: {
    isShowMap() {
      let isShow = false;
      if (this.$route.params.type === 'news' && this.$event.place) {
        isShow = true;
      }
      if (this.$route.params.type === 'event' && (this.$event.place || this.$event.location)) {
        isShow = true;
      }
      return isShow;
    },
    badgeName() {
      const Badge = this.$badges.find((badge) => badge.id === this.$event.badge_id);
      return Badge ? Badge.title : '';
    },
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: this.$route.path.replace(`/${this.$route.params.id}`, ''),
        title: this.$i18n.t('menu.events'),
      }, {
        href: this.$route.path,
        title: this.$event.title,
      }];
    },
    url() {
      return this.$host + this.$route.path;
    },
  },
  methods: {
    svgImg() {
      if (!this.$badgesSVG.length || this.$event.badge_id === null) {
        return '<div></div>';
      }
      const div = document.createElement('div');
      div.innerHTML = this.$badgesSVG.find((svg) => svg.id === this.$event.badge_id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.setAttribute('width', '32px');
      svg.setAttribute('height', '32px');
      div.getElementsByClassName('color-marker-symbol')
        .forEach((el) => {
          const element = el;
          const badgeIconSettings = this.$event.badge
            ? this.$event.badge.icon_settings
            : undefined;
          element.style.fill = badgeIconSettings
            ? badgeIconSettings.color
            : '#000000';
          element.style.opacity = badgeIconSettings
            ? badgeIconSettings.transparency
            : 1;
        });
      return div.innerHTML;
    },
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_CONTENT');
  },
  watch: {
    $route() {
      this.$store.dispatch('GET_CONTENT_FROM_SERVER', {
        type: this.$route.params.type,
        id: this.$route.params.id,
        lang: this.$i18n.locale,
      });
    },
  },
};
</script>
